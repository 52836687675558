<template>
	<div class="card-set el-content">
		<com-power-form :form-data="[
			{label:'赠送分享标题',type:'text',key:'card_send_share_title',dataType:'string'}
		]"></com-power-form>
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		comPowerForm
	}
}
</script>

<style>
</style>
